.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paper {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
  background-color: rgba(255, 255, 255, 0.625);
  border-radius: 10px;
  padding: 16px;
  outline: none;
}

.progress {
  margin-bottom: 16px; /* Add space between CircularProgress and Typography */
}
